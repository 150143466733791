<template>
  <Layout style="height: 100vh;">
    <!-- 头部导航 -->
    <Header>
      <div class="login">
        <img src="@/assets/image/logo.png"/>
      </div>
      <div class="header-content">
        <div class="left">
          <span>{{TimePrompt}}</span>
          <span class="color">{{$store.state.userInfo.name}}</span>
          <span>，欢迎来到云课堂后台管理系统</span>
        </div>
        <div class="right">
          <a-avatar class="img" :src="adminIcon" alt="头像" />
          <img src="" alt="">
          <!-- <span class="head-img">管</span> -->
          <a-dropdown>
            <span class="text">{{$store.state.userInfo.name}}<a-icon class="icon" type="caret-down" /></span>
            <a-menu slot="overlay">
            <!-- <a-menu-item key="overlay0" @click="FunOverlay(0)">
              <a-icon type="form" />
              <span>意见反馈</span>
            </a-menu-item> -->
            <!-- <a href="https://www.hxclass.cn/home/aboutUs" target="_blank" rel="noopener noreferrer"> -->
            <!-- <a-menu-item key="overlay1" @click="FunOverlay(1)">
                <a-icon type="info-circle" style="margin-right:7px;" />
                <span>关于系统</span>
            </a-menu-item> -->
            <!-- </a> -->
            <!-- <a-menu-divider /> -->
              
             <a-menu-item key="overlay3" @click="FunOverlay(3)">
                <a-icon type="unlock" />
                <span>修改密码</span>
              </a-menu-item>

              <a-menu-item key="overlay2" @click="FunOverlay(2)">
                <a-icon type="poweroff" />
                <span>退出系统</span>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </div>
        <!-- 修改密码 -->
        <a-modal
          title="修改密码"
          :visible="visible"
          width="360px"
          @ok="handleOk"
          @cancel="handleCancel"
        >
          <div class="modal_box">
            <span class="tag">原密码：</span>
            <a-input-password class="input-password" v-model="oldPwd" placeholder="请输入原密码" />
          </div>
          <div class="modal_box" style="margin-top: 20px;">
            <span class="tag">新密码：</span>
            <a-input-password class="input-password" v-model="newPwd" placeholder="请输入新密码" />
          </div>
        </a-modal>
      </div>
    </Header>
    <Layout>
      <!-- 侧边栏 -->
      <Sider v-model="collapsed" :collapsible="false" width="205">
        <!-- 导航菜单 -->
        <NavigationMenu/>
      </Sider>
      <!-- 内容部分 -->
      <Content>
        <router-view class="window"/>
      </Content>
    </Layout>
    <!-- 新建/编辑 -->
   
  </Layout>
  
</template>

<script>
import { Layout, Icon } from 'ant-design-vue'
import RouterView from '../routerView.vue'
import { FunTimePrompt } from '@/unit/fun.js'
import NavigationMenu from '@/components/NavigationMenu.vue'
const { Sider, Header, Content } = Layout
export default {
  // 可用组件的哈希表
  components: { Layout, Sider, Header, Content, Icon, RouterView, NavigationMenu },
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      TimePrompt: FunTimePrompt(),
      adminIcon: require('@/assets/image/admin_icon.png'),
      collapsed: false,
      time: null,
      FullScreen: null,
      visible:false,//新增窗口显示隐藏
      oldPwd:"",//旧密码
      newPwd:"",//新密码
    }
  },
  // 事件处理器
  methods: {
    // 点击导航的菜单
    FunOverlay(e){
      if(e == 0){
        // 意见反馈
      }else if(e == 1){
        // 关于系统
      }else if(e == 2){
        // 退出系统
        let _this = this
        this.$confirm({
          title: '提示信息',
          content: '是否确认，退出当前账号？',
          onOk() {
            localStorage.setItem('whiteList','')
            _this.$store.commit('clearUserInfo')
            _this.$router.push('/login/loginIndex')
          },
          onCancel() {},
        });
      }else if(e==3){
        //修改密码
        this.visible=true;
      }
    },
    //确定点击
    handleOk(e){
      if(!this.oldPwd){//判断原密码不为空
        this.$message.warning("请输入原密码");
        return
      }
      if(!this.newPwd){//判断新密码不为空
        this.$message.warning("请输入新密码");
        return
      }
      this.$ajax({
        method: 'PUT',
        url:"/hxclass-management/user/info/updateManagePwd?newPwd="+this.newPwd+"&oldPwd="+this.oldPwd,
      }).then(res => {
        if (res.code == 200 && res.success) {
          this.$message.success('修改成功');
          this.visible=false;
          this.newPwd="";
          this.oldPwd="";
        } else {
          this.$message.warning(res.message);
        }
      })
    },
    handleCancel(e){
      this.visible=false;
    },
  },
  // 生命周期-实例创建完成后调用
  created () {
    this.FullScreen = this.$route.meta.FullScreen
    this.BackColor = this.$route.meta.BackColor || true
    // 一小时更新一次提示语
    this.time = setInterval(()=>{
      this.$set(this, 'TimePrompt', FunTimePrompt())
    },3600000)
  },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () {
    clearInterval(this.time)
    this.time = null
  },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {
    $route(e){
      this.FullScreen = e.meta.FullScreen
      this.BackColor = e.meta.BackColor || true
    }
  }
}
</script>

<style lang="less" scoped>
.ant-layout{
  background-color: #F6F6FC;
}
.ant-layout-sider{
  background-color: #213A4C;
  width: 205px;
}
/deep/.ant-layout-header{
  background-color: #fff;
  height: 84px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 9;
}
.ant-layout-content{
  overflow: auto;
  margin: 11px 8px;
  border-radius: 8px;
  // box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1);
  .window{
    background-color: #fff;
    // padding: 18px 16px;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
  }
}

.login{
  width: 205px;
  height: 100%;
  position: relative;
  flex: none;
  border-right: 1px solid #ddd;
  border-top-right-radius: 50px;
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.15);
  img{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    // max-width: 70%;
    height: 24px;
    // max-height: 70%;
  }
  
}

.header-content{
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  padding: 0 26px;
  flex: 1;
  .left{
    flex: 1;
    font-size: 16px;
    color: #333;
    font-weight: 500;
    .color{
      color: @theme;
    }
  }
  .right{
    display: flex;
    align-items: center;
    .img{
      width: 32px;
      height: 32px;
      // border: 1px solid @theme;
      margin-right: 8px;
    }
    .head-img{
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      background: #2263C5;
      width: 35px;
      height: 35px;
      color: #ffffff;
      border-radius: 50%;
    }
    .text{
      font-size: 16px;
      color: #333;
      font-weight: 500;
      cursor: pointer;
    }
    .icon{
      color: @theme;
      margin-left: 8px;
    }
  }
}
.modal_box {
  display: flex;
  align-items: center;
  .tag {
    flex-shrink: 0;
  }
}
</style>
