<template>
  <a-menu mode="inline" theme="dark" :open-keys.sync="openKeys" :defaultSelectedKeys="SelectedKeys">
    <template v-for="item in treeList"> 
      <a-menu-item :key="item.menuId" v-if="!item.sysMenuList.length">
        <router-link :to="{path: item.menuUrl}">
          <img v-if="item.menuIconUrl" :src="item.menuIconUrl" class="img"/>
          <span>{{item.menuName}}</span>
        </router-link>
      </a-menu-item>
      <!-- 一级 -->
      <a-sub-menu :class="openKeys.indexOf(item.menuId) != '-1'?'a-sub-menuactive':''" :key="item.menuId" v-else>
        <span slot="title">
          <img v-if="item.menuIconUrl" :src="item.menuIconUrl" class="img"/>
          <span>{{item.menuName}}</span>
        </span>
        <template v-for="item in item.sysMenuList">
          <a-menu-item :key="item.menuId" v-if="!item.sysMenuList.length ">
            <router-link :to="{path: item.menuUrl}">
              <img v-if="item.menuIconUrl" :src="item.menuIconUrl" class="img"/>
              <span>{{item.menuName}}</span>
            </router-link>
          </a-menu-item>
          <!-- 二级 -->
          <a-sub-menu :class="openKeys.indexOf(item.menuId) != '-1'?'a-sub-menuactive':''" :key="item.menuId" v-else>
            <span slot="title">
              <img v-if="item.menuIconUrl" :src="item.menuIconUrl" class="img"/>
              <span>{{item.menuName}}</span>
            </span>
            <template v-for="item in item.sysMenuList">
              <a-menu-item :key="item.menuId" v-if="!item.sysMenuList.length ">
                <router-link :to="{path: item.menuUrl}">
                  <img v-if="item.menuIconUrl" :src="item.menuIconUrl" class="img"/>
                  <span>{{item.menuName}}</span>
                </router-link>
              </a-menu-item>
              <!-- 三级 -->
              <a-sub-menu :class="openKeys.indexOf(item.menuId) != '-1'?'a-sub-menuactive':''" :key="item.menuId" v-else>
                <span slot="title">
                  <img v-if="item.menuIconUrl" :src="item.menuIconUrl" class="img"/>
                  <span>{{item.menuName}}</span>
                </span>
                <template v-for="item in item.sysMenuList">
                  <a-menu-item :key="item.menuId" v-if="!item.sysMenuList.length ">
                    <router-link :to="{path: item.menuUrl}" >
                      <img v-if="item.menuIconUrl" :src="item.menuIconUrl" class="img"/>
                      <span>{{item.menuName}}</span>
                    </router-link>
                  </a-menu-item>
                  <!-- 四级 -->
                  <a-sub-menu :class="openKeys.indexOf(item.menuId) != '-1'?'a-sub-menuactive':''" :key="item.menuId" v-else>
                    <span slot="title">
                      <img v-if="item.menuIconUrl" :src="item.menuIconUrl" class="img"/>
                      <span>{{item.menuName}}</span>
                    </span>
                    <a-menu-item key="2">
                      <router-link :to="{path: item.menuUrl}">
                        <span>{{item.menuName}}</span>
                      </router-link>
                    </a-menu-item>
                  </a-sub-menu>
                </template>
              </a-sub-menu>
            </template>
          </a-sub-menu>
        </template>
      </a-sub-menu>
    </template>
  </a-menu>
</template>

<script>
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      openKeys: [],  // 默认打开项
      SelectedKeys:[],  // 默认选中项
      treeList: [],
      path:''
    }
  },
  // 事件处理器
  methods: {
    // 找出当前地址栏路径的父级id和选中项id
    SearchID(arry){
      this.treeList.forEach(element => {
        if(element.menuUrl == this.path){
          this.openKeys = [element.menuId]
          this.SelectedKeys = [element.menuId]
        }
        if(element.sysMenuList){
          element.sysMenuList.forEach(subItem => {
            if(subItem.menuUrl == this.path){
              this.openKeys = [element.menuId]
              this.SelectedKeys = [subItem.menuId]
            }
            if(subItem.sysMenuList){
              subItem.sysMenuList.forEach(childItem => {
                if(childItem.menuUrl == this.path){
                  this.openKeys = [element.menuId,subItem.menuId]
                  this.SelectedKeys = [childItem.menuId]
                }
              });
            }
          });
        }
      });
      if(this.openKeys.length){
        let obj = this.$store.state.userInfo
        obj.openKeys = this.openKeys
        obj.SelectedKeys = this.SelectedKeys
        this.$store.commit("updateUserInfo", obj);
      }
      this.openKeys = this.$store.state.userInfo.openKeys
      this.SelectedKeys = this.$store.state.userInfo.SelectedKeys
    }
  },
  // 生命周期-实例创建完成后调用
  created () {
    this.treeList = this.$store.state.userInfo.menuList
    this.path = this.$route.path // 地址栏路径
    this.SearchID(this.treeList)
  },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {
    $route(){
      // console.log(this.$route.path)
      this.path = this.$route.path
      this.SearchID(this.treeList)
    }
  }
}
</script>

<style lang="less" scoped>
.ant-menu{
  height: calc(100vh - 84px);
  overflow-y: auto;
  background: url(~@/assets/image/20220307105852.png) 0 100% no-repeat ,
            linear-gradient(180deg, #3681F0 0%, #2263C5 100%);
  background-size: 50% 100%;
}
/deep/.ant-menu-inline.ant-menu-sub{
  background: rgba(0, 0, 0, 0);
  box-shadow: 0 0 0;
}

/deep/.ant-menu-item-selected>a{
  i,span{
    position: relative;
    z-index: 1;
  }
}
/deep/.ant-menu-item-selected>a::after{
  content: '';
  width: 0;
  height: 0;
  position:absolute;
  right: 0;
  top: calc(50% - 6px);
  border-top: 6px solid transparent;
  border-right: 6px solid white;
  border-bottom: 6px solid transparent;
}
/deep/.ant-menu-item-selected>a::before{
  background: linear-gradient(180deg, #0F5FD4 0%, #0F5FD5 100%);
  z-index: 0;
}
.ant-menu-submenu-title>span{
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.ant-menu-dark .ant-menu-item-selected > a{
  display: flex;
  flex-direction: row;
  align-items: center;
  .img{
    position: relative;
  }
}
.ant-menu-submenu-title>span::before{
  transition: transform 0.3s;
  transition: opacity 0.5s;
  content: '';
  width: 0;
  height: 0;
  position:absolute;
  left: -15px;
  opacity: 0.65;
  top: calc(50% - 5px);
  transform: rotate(180deg);
  border-top: 6px solid transparent;
  border-right: 6px solid white;
  border-bottom: 6px solid transparent;
}
/deep/.ant-menu-submenu-title:hover{
  span::before{
    opacity: 1 !important;
  }
}
/deep/.ant-menu-submenu-arrow::before{
  display: none;
}
/deep/.ant-menu-submenu-arrow::after{
  display: none;
}
.ant-menu-submenu-open>.ant-menu-submenu-title>span::before{
  transform: rotate(270deg);
  opacity: 1;
}
.img{
  margin-right: 8px;
  width: 18px;
  height: 18px;
}
</style>
